import React, { useEffect, useState} from 'react';
import EntityDetail from "../../components/EntityDetail";
import Sidebar from "../../components/Sidebar";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {useAuthHeader} from "react-auth-kit";
import toast from "react-hot-toast";


const CentreDetail = () => {
  const [centre, setCentre] = useState(null);
  const {id} = useParams();
  const getAuthHeader = useAuthHeader();
  const navigate = useNavigate();

  const fetchCentre = async (id) => {
    try {
      const token = getAuthHeader();
      if (!token) {
        navigate("/");
        toast.error("Přihlášení vypršelo!");
        return
      }
        const response = await axios.post(
          `https://flexioverview.upi-group.cz/costCenterReport/get`,
          {costCenterCode: id},
          {headers: {Authorization: token}}
        );
        setCentre({name: response.data.report.costCenterDetail.name, data: response.data});
    } catch (error) {
      console.error(`Error fetching detailed data:`, error);
      throw error;
    }
  };

  useEffect(() => {
    id && fetchCentre(id);
  }, [id]);

  return (
    <Sidebar>
      <EntityDetail
        entity={centre && centre.data ? centre : null}
        entityName="Střediska"
        apiPath="/costCenter"
        navPath="/centres"
        isCompany={false}
        dateFrom={centre && centre.data ? centre.data.report.costCenterDetail.timeInterval.from : null}
        dateTo={centre && centre.data ? centre.data.report.costCenterDetail.timeInterval.to : null}
      />
    </Sidebar>
  );
};

export default CentreDetail;
