export function createGroupAccountBalance(data) {
  return data.reduce((acc, item) => {
    // Skip items with an empty group
    if (!item.group) return acc;

    // Find or create the group entry
    const groupEntry = acc.find((group) => group.group === item.group);
    if (groupEntry) {
      // Add the account balance to the existing group
      groupEntry.accountBalance += item.accountBalance;
    } else {
      // Create a new group entry
      acc.push({
        group: item.group,
        accountBalance: item.accountBalance,
      });
    }

    return acc;
  }, []);
}