import {IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import toast from "react-hot-toast";
import React, {useState} from "react";

export const toastWithError = (error) =>
  toast((t) => {
    const [showTrace, setShowTrace] = useState(false);

    return (
      <div
        style={{
          maxHeight: "300px",
          overflowY: "auto",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6">
            Detail?
            <IconButton onClick={() => setShowTrace((prev) => !prev)}>
              <VisibilityIcon/>
            </IconButton>
            <IconButton onClick={() => toast.dismiss(t.id)}>
              <CloseIcon/>
            </IconButton>
          </Typography>
        </div>

        {showTrace && (
          <>
            <Typography>
              <b>ErrorLevel: </b> {error.response?.data?.appErrors?.itemList[0]?.errorLevel || "Unknown errorLevel"}
            </Typography>
            <Typography>
              <b> HttpStatusCode: </b>{error.response?.data?.appErrors?.itemList[0]?.httpStatusCode || "Unknown httpStatusCode"}
            </Typography>
            <Typography>
              <b> Message: </b>{error.response?.data?.appErrors?.itemList[0]?.message || "Unknown message"}
            </Typography>
            <Typography>
              <b> Timestamp: </b>{error.response?.data?.appErrors?.itemList[0]?.timestamp || "Unknown timestamp"}
            </Typography>
            <Typography>
              <b> Uuid: </b>{error.response?.data?.appErrors?.itemList[0]?.uuid || "Unknown uuid"}
            </Typography>
            <Typography>
              <b>Trace: </b>{error.response?.data?.appErrors?.itemList[0]?.trace || "No trace available"}
            </Typography>
          </>
        )}
      </div>
    );
  }, {duration: Infinity, position: 'top-center', style: {maxWidth: "1800px", maxHeight: "2000px", padding: 0}});