import React, {useContext, useEffect, useState} from "react";
import {BrowserRouter, Routes, Route, useNavigate, useLocation} from "react-router-dom";
import {AuthProvider, useIsAuthenticated} from "react-auth-kit";
import {RequireAuth} from "react-auth-kit";
import {Toaster} from "react-hot-toast";
import LoginPage from "./pages/LoginPage";
import Activities from "./pages/Activities";
import Groups from "./pages/Groups";
import Centres from "./pages/Centre/Centres";
import Companies from "./pages/Company/Companies";
import CentreDetail from "./pages/Centre/CentreDetail";
import CompanyDetail from "./pages/Company/CompanyDetail";
import {GlobalDataContext, GlobalDataProvider} from "./components/GlobalDataProvider";
import Home from "./pages/Home";
import {LicenseInfo} from '@mui/x-license';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const AppRoutes = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const location = useLocation();
  const [authChecked, setAuthChecked] = useState(false);
  const {clearData} = useContext(GlobalDataContext);

  useEffect(() => {
    if (!isAuthenticated()) {
      clearData();
    } else if (location.pathname === "/") {
      navigate("/home");
    }
    setAuthChecked(true);
  }, [isAuthenticated, navigate, location.pathname, clearData]);

  if (!authChecked) {
    return null;
  }

  return (
    <Routes>
      <Route path="/activities" element={<RequireAuth loginPath="/" children={<Activities/>}/>}/>
      <Route path="/home" element={<RequireAuth loginPath="/" children={<Home/>}/>}/>
      {/*<Route path="/users" element={<RequireAuth loginPath="/" children={<Users/>}/>}/>*/}
      {/*<Route path="/tasks" element={<RequireAuth loginPath="/" children={<Tasks/>}/>}/>*/}
      <Route path="/groups" element={<RequireAuth loginPath="/" children={<Groups/>}/>}/>
      <Route path="/centres" element={<RequireAuth loginPath="/" children={<Centres/>}/>}/>
      <Route path="/centre/:id" element={<RequireAuth loginPath="/" children={<CentreDetail/>}/>}/>
      <Route path="/companies" element={<RequireAuth loginPath="/" children={<Companies/>}/>}/>
      <Route path="/company/:id" element={<RequireAuth loginPath="/" children={<CompanyDetail/>}/>}/>
      <Route path="/" element={<LoginPage/>}/>
    </Routes>
  );
}

function App() {
  return (
    <div className="App">
      <AuthProvider authType={"cookie"} authName={"_auth"} cookieSecure={false}>
        <BrowserRouter>
          <GlobalDataProvider>
            <AppRoutes/>
          </GlobalDataProvider>
        </BrowserRouter>
      </AuthProvider>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
    </div>
  );
}

export default App;
