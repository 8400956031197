import dayjs from "dayjs";

export function generateMonthArray(from, to) {
  if (!from || !to) {
    return [];
  }
  const fromMonth = parseInt(from.substring(0, 2), 10);
  const fromYear = parseInt(`20${from.substring(2, 4)}`, 10);
  const toMonth = parseInt(to.substring(0, 2), 10);
  const toYear = parseInt(`20${to.substring(2, 4)}`, 10);

  let startDate = dayjs(new Date(fromYear, fromMonth - 1, 1));
  const endDate = dayjs(new Date(toYear, toMonth - 1, 1));

  const months = [];

  while (startDate.isBefore(endDate) || startDate.isSame(endDate, 'month')) {
    const month = startDate.format('MMYY');
    months.push(month);
    startDate = startDate.add(1, 'month');
  }

  return months;
}

export function generateEmptyUserValueList(phaseList, businessMonths) {
  return phaseList.map(phase => ({
    label: phase,
    businessMonthScopeList: businessMonths.map(month => ({
      businessMonth: month,
      value: 0
    })),
    businessMonthAssumptionList: businessMonths.map(month => ({
      businessMonth: month,
      value: 0
    }))
  }));
  }

export function generateUserValueListWithDistribution(phaseList, businessMonths, assumption, scope) {
  const totalPhases = phaseList.length;
  const totalMonths = businessMonths.length;
  const totalPhaseMonthCount = totalPhases * totalMonths;

  const assumptionPerPhaseMonth = assumption / totalPhaseMonthCount;
  const scopePerPhaseMonth = scope / totalPhaseMonthCount;

  return phaseList.map((phase) => ({
    label: phase,
    businessMonthScopeList: businessMonths.map((month) => ({
      businessMonth: month,
      value: scopePerPhaseMonth,
    })),
    businessMonthAssumptionList: businessMonths.map((month) => ({
      businessMonth: month,
      value: assumptionPerPhaseMonth,
    })),
  }));
}

