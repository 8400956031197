import React from 'react';
import { unstable_composeClasses as composeClasses } from '@mui/utils';
import {
  useGridRootProps,
  useGridApiContext,
  useGridSelector,
  gridFilteredDescendantCountLookupSelector,
  getDataGridUtilityClass,
} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';

const useUtilityClasses = (ownerState) => {
  const { classes } = ownerState;

  const slots = {
    root: ['treeDataGroupingCell'],
    toggle: ['treeDataGroupingCellToggle'],
  };

  return composeClasses(slots, getDataGridUtilityClass, classes);
};

export function CustomGridTreeDataGroupingCell(props) {
  const { id, field, formattedValue, rowNode, hideDescendantCount = true, offsetMultiplier = 2 } = props;

  const rootProps = useGridRootProps();
  const apiRef = useGridApiContext();
  const ownerState = { classes: rootProps.classes };
  const classes = useUtilityClasses(ownerState);
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  );

  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] || 0;

  const Icon = rowNode.childrenExpanded
    ? rootProps.slots.treeDataCollapseIcon
    : rootProps.slots.treeDataExpandIcon;

  const handleClick = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  const value = formattedValue === undefined ? rowNode.groupingKey : formattedValue;

  return (
    <Box
      className={classes.root}
      sx={{
        ml: rowNode.depth * offsetMultiplier,
      }}
    >
      <div className={classes.toggle}>
        {filteredDescendantCount > 0 && (
          <rootProps.slots.baseIconButton
            size="small"
            onClick={handleClick}
            tabIndex={-1}
            aria-label={
              rowNode.childrenExpanded
                ? apiRef.current.getLocaleText('treeDataCollapse')
                : apiRef.current.getLocaleText('treeDataExpand')
            }
            {...(rootProps?.slotProps?.baseIconButton || {})}
          >
            <Icon fontSize="inherit" />
          </rootProps.slots.baseIconButton>
        )}
      </div>
      <span title={value}>
        {value}
        {!hideDescendantCount && filteredDescendantCount > 0 ? ` (${filteredDescendantCount})` : ''}
      </span>
    </Box>
  );
}