import dayjs from "dayjs";

export const convertDateToMMYY = (dateString) => {
  const date = new Date(dateString.split('+')[0]);
  if (isNaN(date.getTime())) {
    return 'Invalid Date';
  }
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear().toString().slice(-2);

  const formattedMonth = month.toString().padStart(2, '0');
  return `${formattedMonth}${year}`;
};

export function convertDateToMMYYDayjs(date) {
  return dayjs(date).format('MMYY').toString();
}

export  function convertStringToDate(dateString) {
  if (!dateString || dateString === '') {
    return
  }
  if (dateString.length !== 4) {
    return
  }
  const month = parseInt(dateString.substring(0, 2), 10);
  const year = parseInt(`20${dateString.substring(2, 4)}`, 10);

  return dayjs(new Date(year, month - 1, 1));
}