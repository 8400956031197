import React, {useContext, useEffect} from "react";
import toast from "react-hot-toast";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import {useAuthHeader} from "react-auth-kit";
import {useNavigate} from "react-router-dom";
import {GlobalDataContext} from "./GlobalDataProvider";

export const RefreshButton = () => {
  const {setUpdateMessage} = useContext(GlobalDataContext);
  const {getUpdatedDataTime} = useContext(GlobalDataContext);
  const {isDataUpdating} = useContext(GlobalDataContext);
  const {setIsDataUpdating} = useContext(GlobalDataContext);
  const getAuthHeader = useAuthHeader();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isDataUpdating) {
      getUpdatedDataTime();
    }
  }, [isDataUpdating]);

  const handleDataRefresh = async () => {
    const token = getAuthHeader();
    if (!token) {
      navigate("/");
      toast.error("Přihlášení vypršelo!");
      return
    }
    try {
      await axios.get(`https://flexioverview.upi-group.cz/dataset/update`, {
        headers: { Authorization: token },
      });
      setIsDataUpdating(true);
      getUpdatedDataTime();
    } catch (error) {
      setUpdateMessage("Při aktualizaci dat došlo k chybě. Zkuste to znovu...");
      console.error(error);
    }
  };

  return (
      <LoadingButton
        size="small"
        onClick={handleDataRefresh}
        endIcon={<RefreshIcon />}
        loading={isDataUpdating}
        loadingPosition="end"
        variant="contained"
      >
        Aktualizovat
      </LoadingButton>
  );
};