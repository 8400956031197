import {useContext, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText, InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from '@mui/icons-material/Delete';
import {GlobalDataContext} from "./GlobalDataProvider";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {csCZ} from "@mui/x-date-pickers/locales";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import Grid from "@mui/material/Grid2";
import {useAuthHeader} from "react-auth-kit";
import axios from "axios";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import {NumericFormat} from "react-number-format";
import {toastWithError} from "./ToastWithError";
import {removeTimezone} from "../utils/Formater";
import {generateMonthArray, generateUserValueListWithDistribution} from "../utils/ValueGenerator";
import {convertDateToMMYY} from "../utils/Coverter";

const dayjs = require('dayjs');

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: '70vh',
  maxHeight: '90vh',
  overflowY: 'auto',
  boxShadow: 24,
  bgcolor: 'background.paper',
  p: "0px 40px 30px 40px",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const ContractEdit = ({mode, selectedContract, opened, data, handleClose, updateRows, deleteContract}) => {
  const {listOfCentres} = useContext(GlobalDataContext);
  const {getUpdatedDataTime} = useContext(GlobalDataContext);
  const {setUpdateMessage} = useContext(GlobalDataContext);
  const [sortedCentres, setSortedCentres] = useState([]);
  const [errors, setErrors] = useState({});
  const [contract, setContract] = useState({
    code: null,
    description: null,
    costCenter: null,
    scope: 0,
    costAssumption: 0,
    startDate: null,
    endDate: null,
    contractNumber: null,
    status: null,
    userValueList: null,
    groupList: [],
    phaseList: [],
    sourceList: [],
  });
  const [updatedCode, setUpdatedCode] = useState(null);
  const [groupListOptions, setGroupListOptions] = useState([]);
  const [phaseListOptions, setPhaseListOptions] = useState([]);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const getAuthHeader = useAuthHeader();
  const navigate = useNavigate();

  useEffect(() => {
    if (opened) {
      mode === "create" && fetchLabels();
      mode === "update" && fetchUpdateData();
    } else {
      clearForm();
    }
  }, [opened]);

  useEffect(() => {
    if (listOfCentres && listOfCentres.length > 0) {
      const sorted = [...listOfCentres].sort((a, b) => a.code.localeCompare(b.code));
      setSortedCentres(sorted);
    }
  }, [listOfCentres]);

  useEffect(() => {
    if (contract.phaseList && contract.startDate && contract.endDate && mode === "create") {
      const userValueList = generateUserValueListWithDistribution(contract.phaseList, generateMonthArray(convertDateToMMYY(contract.startDate), convertDateToMMYY(contract.endDate)), contract.costAssumption, contract.scope,);
      handleInputChange("userValueList", userValueList);
    }

  }, [contract.phaseList, contract.scope, contract.costAssumption, contract.startDate, contract.endDate]);

  const fetchUpdateData = async () => {
    try {
      setContract({
        code: selectedContract.code === "" ? null : selectedContract.code,
        description: selectedContract.description === "" ? null : selectedContract.description,
        costCenter: selectedContract.costCenter.toString() === "" ? null : selectedContract.costCenter,
        scope: parseFloat(selectedContract.scope),
        costAssumption: parseFloat(selectedContract.costAssumption),
        startDate: selectedContract.startDate === "" ? null : selectedContract.startDate,
        endDate: selectedContract.endDate === "" ? null : selectedContract.endDate,
        status: selectedContract.status === "" ? null : selectedContract.status,
        groupList: selectedContract.groupList,
        phaseList: selectedContract.phaseList,
        sourceList: [],
      });
      setUpdatedCode(selectedContract.code === "" ? null : selectedContract.code);
      fetchLabels();
    } catch (error) {
      console.error(error);
    }
  };

  const fetchLabels = async () => {
    try {
      data.report.labelGroupList.forEach(label => {
        if (label.group) setGroupListOptions(label.labelList);
        if (label.phase) setPhaseListOptions(label.labelList);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const clearForm = () => {
    setContract({
      code: null,
      description: null,
      costCenter: null,
      scope: 0,
      costAssumption: 0,
      startDate: null,
      endDate: null,
      status: null,
      updatedCode: null,
      groupList: [],
      phaseList: [],
      sourceList: [],
      userValueList: [],
    });
    setGroupListOptions([]);
    setPhaseListOptions([]);
    setErrors({});
  };


  const validateForm = (data) => {
    const errors = {};

    if (!data.code || data.code.trim() === '') {
      errors.code = 'Kód je poviný';
    } else if (/[a-z]/.test(data.code)) {
      errors.code = 'Kód nesmí obsahovat malá písmena';
    }

    if (!data.description || data.description.trim() === '') {
      errors.description = 'Název je povinný';
    }
    if (!data.costCenter || data.costCenter.trim() === '') {
      errors.costCenter = 'Středisko je povinné';
    }
    if (!data.startDate || data.startDate.trim() === '') {
      errors.startDate = 'Datum od je povinné';
    } else if (!dayjs(removeTimezone(data.startDate), 'YYYY-MM-DD', true).isValid()) {
      errors.startDate = 'Neplatný formát';
    }
    if (!data.endDate || data.endDate.trim() === '') {
      errors.endDate = 'Datum do je povinné';
    } else if (!dayjs(removeTimezone(data.endDate), 'YYYY-MM-DD', true).isValid()) {
      errors.endDate = 'Neplatný formát';
    }

    if (!errors.startDate && !errors.endDate) {
      const firstDate = dayjs(removeTimezone(data.startDate), 'YYYY-MM-DD', true);
      const secondDate = dayjs(removeTimezone(data.endDate), 'YYYY-MM-DD', true);

      if (firstDate.isAfter(secondDate)) {
        errors.startDate = 'Datum zahájení musí být menší než datum ukončení';
        errors.endDate = 'Datum ukončení musí být větší než datum zahájení';
      }
    }

    if (data.groupList.length === 0) {
      errors.groupList = 'Skupina je povinná';
    }
    if (data.phaseList.length === 0) {
      errors.phaseList = 'Fáze jsou povinné';
    }

    return errors;
  };

  const handleInputChange = (field, value) => {
    if (field === "code" && mode === "update") {
      setUpdatedCode(value);
    } else {
      setContract((prev) => ({...prev, [field]: value}));
    }
    setErrors((prev) => {
      const updatedErrors = {...prev};
      delete updatedErrors[field];
      return updatedErrors;
    });
  };

  const handleSave = async () => {
    try {
      console.log("Contract: ", {contract});
      setSaving(true);
      const token = getAuthHeader();
      if (!token) {
        navigate("/");
        toast.error("Přihlášení vypršelo!");
        return
      }
      const errors = validateForm(contract);
      setErrors(errors)
      if (Object.keys(errors).length > 0) {
        setSaving(false);
        toast.error(`Vyplňte všechna povinná pole!`);
        return
      }
      const updatingDataset = await axios.get(
        `https://flexioverview.upi-group.cz/dataset/get`,
        {headers: {Authorization: token}}
      );
      if (updatingDataset.data.updating) {
        setSaving(false);
        toast.error(`Právě aktualizuji data. Zkuste to znovu později!`);
        setUpdateMessage(updatingDataset.data.message);
        getUpdatedDataTime();
        return
      }
      const response = await axios.post(
        `https://flexioverview.upi-group.cz/contract/${mode}`,
        {
          ...contract,
          companyCode: data.report.companyDetail.code,
          updatedCode: mode === "update" ? updatedCode : null
        },
        {headers: {Authorization: token}}
      );
      toast.success(`Zakázka byla ${mode === "create" ? "vytvořena" : "upravená"}!`);
      const newContract = response.data.flexiContract;
      console.log("newContract: ", newContract);
      const newRow = {
        code: newContract.code,
        costCenter: newContract.costCenter,
        description: newContract.description,
        contractNumber: newContract.contractNumber,
        startDate: newContract.startDate,
        endDate: newContract.endDate,
        scope: newContract.scope,
        costAssumption: newContract.costAssumption,
        status: newContract.status,
        userValueList: newContract.userValueList,
        groupList: newContract.groupList,
        phaseList: newContract.phaseList,
        sourceList: newContract.sourceList,

      }
      updateRows(contract.code, newRow, newContract.costCenter);
      handleClose();
      setSaving(false);

    } catch (error) {
      console.error(error);
      toastWithError(error);
      toast.error(`Zakázku se nepodařilo ${mode === "create" ? "vytvořit" : "upravit"}!`);
      setSaving(false);
    }
  };

  const handleDelete = async () => {
    try {
      setDeleting(true);
      const token = getAuthHeader();
      if (!token) {
        setDeleting(false);
        navigate("/");
        toast.error("Přihlášení vypršelo!");
        return
      }
      const updatingDataset = await axios.get(
        `https://flexioverview.upi-group.cz/dataset/get`,
        {headers: {Authorization: token}}
      );
      if (updatingDataset.data.updating) {
        setDeleting(false);
        toast.error(`Právě aktualizuji data. Zkuste to znovu později!`);
        setUpdateMessage(updatingDataset.data.message);
        return
      }
      const response = await axios.post(
        `https://flexioverview.upi-group.cz/contract/delete`,
        {
          companyCode: data.report.companyDetail.code,
          code: contract.code
        },
        {headers: {Authorization: token}}
      );
      toast.success(`Zakázka byla smazána!`);
      deleteContract(response.data.flexiContract.code, response.data.flexiContract.costCenter);
      handleClose();
      setDeleting(false);
    } catch (error) {
      console.error(error);
      toastWithError(error);
      toast.error(`Zakázku se nepodařilo smazat!`);
      setDeleting();
    }
  };




  return (
    <Modal
      open={opened}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h5" sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '20px',
          paddingBottom: '20px',
          fontWeight: 'bold',
        }}>
          {mode === "update" ? "Úprava zakázky" : "Vytváření nové zakázky"}
        </Typography>
        <Grid container spacing={2} justifyContent="space-around">
          <Grid size={6}>
            *Zak:
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              error={!!errors.code}
              helperText={errors.code}
              value={mode === "update" ? updatedCode : contract.code}
              onChange={(e) => handleInputChange("code", e.target.value)}
              onBlur={(e) => handleInputChange("code", e.target.value.toUpperCase())}
            />
          </Grid>
          <Grid size={6}>
            *Název:
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              error={!!errors.description}
              helperText={errors.description}
              value={contract.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
            />
          </Grid>
          <Grid size={6}>
            Stav:
            <Select
              fullWidth
              variant="outlined"
              size="small"
              value={contract.status}
              onChange={(e) => handleInputChange("status", e.target.value)}
            >
              <MenuItem value="PLAN">PLAN</MenuItem>
              <MenuItem value="PROBIHA">PROBIHA</MenuItem>
              <MenuItem value="UKONCENO">UKONCENO</MenuItem>
              <MenuItem value="STORNO">STORNO</MenuItem>
            </Select>
          </Grid>
          <Grid size={6}>
            *Středisko:
            <br/>
            <Autocomplete
              sx={{ minWidth: "100%" }}
              value={sortedCentres.find((centre) => centre.id === contract.costCenter) || null} // Find the current value
              onChange={(e, newValue) =>
                handleInputChange("costCenter", newValue ? newValue.id : "")
              }
              options={sortedCentres} // Pass the list of options
              getOptionLabel={(option) => `${option.code} - ${option.name}`} // Display code and name
              isOptionEqualToValue={(option, value) => option.id === value.id} // Compare options and value
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  error={!!errors.costCenter} // Highlight error
                  helperText={errors.costCenter} // Show error message
                />
              )}
            />
          </Grid>
          <Grid size={6}>
            Plán:
            <NumericFormat
              customInput={TextField} // Use MUI TextField as the input component
              value={contract.costAssumption}
              onValueChange={(values) => handleInputChange("costAssumption", values.floatValue || 0)}// Update the value when the user types
              onFocus={(e) => e.target.select()}
              thousandSeparator=" " // Format with commas for thousands separator
              decimalSeparator="," // Use period for decimal separator
              decimalScale={0} // Limit to 2 decimal places
              allowNegative={false} // Disable negative values
              isNumericString={true} // Make sure it's treated as a numeric string
              InputProps={{
                endAdornment: <InputAdornment position='start'>Kč</InputAdornment>
              }}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            Smlouva:
            <NumericFormat
              customInput={TextField} // Use MUI TextField as the input component
              value={contract.scope}
              onValueChange={(values) => handleInputChange("scope", values.floatValue  || 0)} // Update the value when the user types
              onFocus={(e) => e.target.select()}
              thousandSeparator=" " // Format with commas for thousands separator
              decimalSeparator="," // Use period for decimal separator
              decimalScale={0} // Limit to 2 decimal places
              allowNegative={false} // Disable negative values
              isNumericString={true} // Make sure it's treated as a numeric string
              InputProps={{
                endAdornment: <InputAdornment position='start'>Kč</InputAdornment>
              }}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            *Datum zahájení:
            <LocalizationProvider adapterLocale="cs" dateAdapter={AdapterDayjs}
                                  localeText={csCZ.components.MuiLocalizationProvider.defaultProps.localeText}>
              <DatePicker views={['year', 'month', 'day']}
                          onChange={(newValue) => handleInputChange("startDate", dayjs(newValue).format('YYYY-MM-DD') + "+00:00")}
                          value={contract.startDate != null ? dayjs(removeTimezone(contract.startDate)) : null}
                          slotProps={{
                            textField: {
                              size: 'small',
                              fullWidth: true,
                              error: !!errors.startDate,
                              helperText: errors.startDate,
                            },
                          }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid size={6}>
            *Datum ukončení:
            <LocalizationProvider adapterLocale="cs" dateAdapter={AdapterDayjs}
                                  localeText={csCZ.components.MuiLocalizationProvider.defaultProps.localeText}>
              <DatePicker views={['year', 'month', 'day']}
                          onChange={(newValue) => handleInputChange("endDate", dayjs(newValue).format('YYYY-MM-DD') + "+00:00")}
                          value={contract.endDate != null ? dayjs(removeTimezone(contract.endDate)) : null}
                          slotProps={{
                            textField: {
                              size: 'small',
                              fullWidth: true,
                              error: !!errors.endDate,
                              helperText: errors.endDate,
                            },
                          }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid size={6}>
            *Skupina:
            <br/>
            <FormControl sx={{minWidth: "100%"}} error={errors.groupList}>
              <Select
                value={contract.groupList}
                onChange={(e) => handleInputChange("groupList", [e.target.value])}
                displayEmpty
                size="small"
                inputProps={{'aria-label': 'Without label'}}
              >
                {groupListOptions.map((group) => (
                  <MenuItem key={group} value={group}>{group}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.groupList}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid size={6}>
            *Fáze:
            <br/>
            <FormControl sx={{minWidth: "100%"}} error={errors.phaseList}>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={contract.phaseList || []}
                onChange={(e) => handleInputChange("phaseList", e.target.value)}
                input={<OutlinedInput/>}
                MenuProps={MenuProps}
                sx={{width: "100%"}}
                size="small"
              >
                {phaseListOptions.map((phase) => (
                  <MenuItem
                    key={phase}
                    value={phase}
                    //style={getStyles(name, personName, theme)}
                  >
                    {phase}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.phaseList}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid container sx={{paddingTop: '20px', width: '100%'}}>
            {mode === "update" && <Grid size={6}>
              <LoadingButton variant="outlined" disabled={saving} color="error" loading={deleting}
                             loadingPosition="start" onClick={handleDelete} startIcon={<DeleteIcon/>}>
                Smazat
              </LoadingButton>
            </Grid>
            }
            <Grid size={mode === "update" ? 6 : 12} sx={{display: 'flex', justifyContent: 'center'}}>
              <Button variant="outlined" onClick={handleClose} disabled={saving || deleting} alignSelf="flex-start"
                      startIcon={<CloseIcon/>}>
                Zrušit
              </Button>
              <LoadingButton variant="contained" onClick={handleSave} loading={saving} disabled={deleting}
                             loadingPosition="end" sx={{marginLeft: 2}}
                             endIcon={<SaveIcon/>}>
                Uložit
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
export default ContractEdit;
