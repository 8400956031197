export function removeTimezone(dateString) {
  if (!dateString) return ''; // Return an empty string if dateString is invalid

  const plusIndex = dateString.indexOf('+');
  if (plusIndex !== -1) {
    return dateString.slice(0, plusIndex);
  }
  return dateString;
}

export function formatAmount(number) {
  if (number === null || number === '' || isNaN(number)) {
    return '';
  }
  let roundedNumber = Math.round(number); // Round to the nearest whole number
  return roundedNumber.toLocaleString('cs-CZ'); // Format using locale without decimal places
}

export function formatDate(dateString) {
  if (!dateString) return ''; // Return an empty string if dateString is invalid

  const dateWithoutTimezone = removeTimezone(dateString);
  const date = new Date(dateWithoutTimezone);

  if (isNaN(date)) return ''; // Check if date is invalid

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
}

export function formatDateAddSlash(input) {
  if (input.length !== 4) {
    return input;
  }
  return `${input.slice(0, 2)}/${input.slice(2)}`;
}


