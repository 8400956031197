import React, {useContext, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {Button, Divider, TextField, Typography} from "@mui/material";
import Grid from '@mui/material/Grid2';
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import toast from "react-hot-toast";
import {useAuthHeader} from "react-auth-kit";
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from "@mui/lab/LoadingButton";
import { NumericFormat } from 'react-number-format';
import {useNavigate} from "react-router-dom";
import {toastWithError} from "./ToastWithError";
import {GlobalDataContext} from "./GlobalDataProvider";
import { formatDateAddSlash} from "../utils/Formater";
import { generateMonthArray, generateEmptyUserValueList} from "../utils/ValueGenerator";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%', // Default width for both PC and mobile
  maxWidth: '80vh', // Maximum width for PC
  maxHeight: '90vh', // Adjust the height as needed
  overflowY: 'auto', // Enable vertical scrolling
  boxShadow: 24,
  bgcolor: 'background.paper',
  p: "0px 40px 40px 40px",
};

const FinancialPlanPopUp = ({
                              opened,
                              handleClose,
                              selectedContract,
                              updateContract,
                              company
                            }) => {
  const {setUpdateMessage} = useContext(GlobalDataContext);
  const {getUpdatedDataTime} = useContext(GlobalDataContext);
  const [saving, setSaving] = useState(false);
  const [userValueList, setUserValueList] = useState([]);
  const getAuthHeader = useAuthHeader();
  const navigate = useNavigate();

  useEffect(() => {
    if (opened) {
      fetchUserValues(selectedContract);
    }else {
      setUserValueList([]);
    }
  }, [opened]);

  function fetchUserValues(selectedContract) {
    const { startDate, endDate, phaseList, userValueList } = selectedContract;

    const businessMonths = generateMonthArray(convertDateToMMYY(startDate), convertDateToMMYY(endDate));

    const defaultValues = generateEmptyUserValueList(phaseList, businessMonths);

    userValueList.forEach(userValue => {
      const targetPhase = defaultValues.find(phase => phase.label === userValue.label);
      if (targetPhase) {
        ['businessMonthScopeList', 'businessMonthAssumptionList'].forEach(key => {
          userValue[key]?.forEach(userValueEntry => {
            const targetMonth = targetPhase[key].find(
              defaultEntry => defaultEntry.businessMonth === userValueEntry.businessMonth
            );
            if (targetMonth) {
              targetMonth.value = userValueEntry.value;
            }
          });
        });
      }
    });
    setUserValueList(defaultValues);

  }


  function formatAmount(number) {
    if (number === null || number === '' || isNaN(number)) {
      return '';
    }
    let roundedNumber = Math.round(number);
    return roundedNumber.toLocaleString('cs-CZ');
  }

  function removeTimezone(dateString) {
    if (!dateString) {
      return dateString;
    }
    const plusIndex = dateString.indexOf('+');
    if (plusIndex !== -1) {
      return dateString.slice(0, plusIndex);
    }
    return dateString;
  }

  const convertDateToMMYY = (dateString) => {
    const date = new Date(dateString.split('+')[0]);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear().toString().slice(-2);

    const formattedMonth = month.toString().padStart(2, '0');
    return `${formattedMonth}${year}`;
  };


  const calculateTotalScope = () => {
    let totalScope = 0;
    userValueList.forEach(userValue => {
      userValue.businessMonthScopeList.forEach(scope => {
        totalScope += scope.value || 0;
      });
    });
    return totalScope;
  }

  const calculateTotalAssumption = () => {
    let totalAssumption = 0;
    userValueList.forEach(userValue => {
      userValue.businessMonthAssumptionList.forEach(assumption => {
        totalAssumption += assumption.value || 0;
      });
    });
    return totalAssumption;
  }

  const calculateActualPaymentMonthSum = (businessMonth, contractCode) => {
    return company.receivedInvoiceList
      .filter((invoice) => invoice.contract === contractCode) // Match by contractCode
      .reduce((sum, invoice) => {
        // Sum up payment amounts for the specified businessMonth
        const monthlyPayments = invoice.paymentList
          ?.filter((payment) => convertDateToMMYY(payment.paymentDate) === businessMonth) // Match the month
          .reduce((paymentSum, payment) => paymentSum + payment.paymentAmount, 0) || 0;

        return sum + monthlyPayments;
      }, 0);
  };
  const calculateActualPaymentSum = (contractCode) => {
    return company.receivedInvoiceList
      .filter((invoice) => invoice.contract === contractCode) // Match invoices by contractCode
      .reduce((sum, invoice) => {
        // Sum up all payments in the paymentList
        const totalPayments = invoice.paymentList
          ?.reduce((paymentSum, payment) => paymentSum + payment.paymentAmount, 0) || 0;

        return sum + totalPayments;
      }, 0);
  };

  const handleChangeAssumption = (values, month, phase) => {
    const newAssumptionValue = values.floatValue || 0;

    setUserValueList((prevList) =>
      prevList.map((userValue) => {
        if (userValue.label === phase) {
          return {
            ...userValue,
            businessMonthAssumptionList: userValue.businessMonthAssumptionList.map((item) =>
              item.businessMonth === month
                ? { ...item, value: newAssumptionValue }
                : item
            ),
          };
        }
        return userValue;
      })
    );
  };

  const handleChangeScope = (values, month, phase) => {
    const newScopeValue = values.floatValue || 0;

    setUserValueList((prevList) =>
      prevList.map((userValue) => {
        if (userValue.label === phase) {
          return {
            ...userValue,
            businessMonthScopeList: userValue.businessMonthScopeList.map((item) =>
              item.businessMonth === month ? { ...item, value: newScopeValue } : item
            ),
          };
        }
        return userValue;
      })
    );
  };

  const handlePopUpSubmit = async () => {
    try {
      setSaving(true);
      const token = getAuthHeader();
      if (!token) {
        setSaving(false);
        navigate("/");
        toast.error("Přihlášení vypršelo!");
        return
      }
      const updatingDataset = await axios.get(
        `https://flexioverview.upi-group.cz/dataset/get`,
        {headers: {Authorization: token}}
      );
      if (updatingDataset.data.updating) {
        setSaving(false);
        toast.error(`Právě aktualizuji data. Zkuste to znovu později!`);
        setUpdateMessage(updatingDataset.data.message);
        getUpdatedDataTime();
        return
      }
      const contract = company.contractList.find(
        (contract) => contract.code === selectedContract.code
      );

      const response = (await axios.post(
        "https://flexioverview.upi-group.cz/contract/updateSettings",
        {
          costCenterCode: contract.costCenter,
          contractCode: selectedContract.code,
          userValueList: userValueList
        },
        {headers: {Authorization: token}}
      )).data.contract;

      toast.success("Finanční plán byl aktualizován!");
      handleClose();
      updateContract(response.contractCode, response.userValueList, company.companyDetail.code, response.costCenterCode);
      setSaving(false);
    } catch (error) {
      toastWithError(error);
      toast.error("Finanční plán se nepodařilo aktualizovat!");
      console.error('Error updating time interval:', error);
      setSaving(false);
    }
  };

  const handleClearAssumption = () => {
    userValueList.forEach(userValue => {
      userValue.businessMonthAssumptionList.forEach(assumption => {
        assumption.value = 0;
      });
    });

    setUserValueList([...userValueList]);
  };

  const handleClearScope = () => {
    userValueList.forEach(userValue => {
      userValue.businessMonthScopeList.forEach(scope => {
        scope.value = 0;
      });
    });

    setUserValueList([...userValueList]);
  };


  return (
    selectedContract &&
    <Modal
      open={opened}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid container sx={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 1,
          height: '100%',
          paddingBottom: '13px'
        }}>
          <Grid size={12}>
            <Typography variant="h5" sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '20px',
              paddingBottom: '20px',
              fontWeight: 'bold',
            }}>
              Finanční plán zakázky
            </Typography>
          </Grid>
          <Grid size={2}>
            <Typography variant="body1" sx={{marginBottom: '10px'}}>
              <strong>Kód zakázky:</strong>
            </Typography>
          </Grid>
          <Grid size={2}>
            <Typography variant="body1" sx={{marginBottom: '10px', textAlign: 'right'}}>
              {selectedContract.code}
            </Typography>
          </Grid>
          <Grid size={2}/>
          <Grid size={2}>
            <Typography variant="body1" sx={{marginBottom: '10px'}}>
              <strong>Číslo smlouvy:</strong>
            </Typography>
          </Grid>
          <Grid size={2}>
            <Typography variant="body1" sx={{marginBottom: '10px', textAlign: 'right'}}>
              {selectedContract.contractCode}
            </Typography>
          </Grid>
          <Grid size={2}/>
          <Grid size={2}>
            <Typography variant="body1" sx={{marginBottom: '10px'}}>
              <strong>Platí od:</strong>
            </Typography>
          </Grid>
          <Grid size={2}>
            <Typography variant="body1" sx={{marginBottom: '10px', textAlign: 'right'}}>
              {removeTimezone(selectedContract.startDate)}
            </Typography>
          </Grid>
          <Grid size={2}/>
          <Grid size={2}>
            <Typography variant="body1" sx={{marginBottom: '10px'}}>
              <strong>Platí do:</strong>
            </Typography>
          </Grid>
          <Grid size={2}>
            <Typography variant="body1" sx={{marginBottom: '10px', textAlign: 'right'}}>
              {removeTimezone(selectedContract.endDate)}
            </Typography>
          </Grid>
          <Grid size={2}/>
          <Grid size={2}>
            <Typography
              variant="body1"
              sx={{marginBottom: '10px', color: selectedContract.costAssumption >= 0 ? 'inherit' : 'red'}}
            >
              <strong>Aktuální plán: </strong>
            </Typography>
          </Grid>
          <Grid size={2}>
            <Typography
              variant="body1"
              sx={{
                marginBottom: '10px',
                textAlign: 'right',
                color: selectedContract.costAssumption >= 0 ? 'inherit' : 'red'
              }}
            >
              {formatAmount(selectedContract.costAssumption)}
            </Typography>
          </Grid>
          <Grid size={2}/>
          <Grid size={2.5}>
            <Typography
              variant="body1"
              sx={{marginBottom: '10px', color: selectedContract.scope >= 0 ? 'inherit' : 'red'}}
            >
              <strong>Aktuální smlouva: </strong>
            </Typography>
          </Grid>
          <Grid size={1.5}>
            <Typography
              variant="body1"
              sx={{marginBottom: '10px', textAlign: 'right', color: selectedContract.scope >= 0 ? 'inherit' : 'red'}}
            >
              {formatAmount(selectedContract.scope)}
            </Typography>
          </Grid>
          <Grid size={2}/>
          <Grid size={2}>
            <Typography variant="body1" sx={{
              marginBottom: '10px',
              color: Math.abs(calculateTotalAssumption() - selectedContract.costAssumption) < 1 ? 'green' : 'red'
            }}>
              <strong>Nový plán: </strong>
            </Typography>
          </Grid>
          <Grid size={2}>
            <Typography variant="body1" sx={{
              marginBottom: '10px',
              color: Math.abs(calculateTotalAssumption() - selectedContract.costAssumption) < 1 ? 'green' : 'red',
              textAlign: 'right'
            }}>
              {formatAmount(calculateTotalAssumption())}
            </Typography>
          </Grid>
          <Grid size={2}/>
          <Grid size={2}>
            <Typography variant="body1" sx={{
              marginBottom: '10px',
              color: Math.abs(calculateTotalScope() - selectedContract.scope) < 1 ? 'green' : 'red'
            }}>
              <strong>Nová smlouva: </strong>
            </Typography>
          </Grid>
          <Grid size={2}>
            <Typography variant="body1" sx={{
              marginBottom: '10px',
              color: Math.abs(calculateTotalScope() - selectedContract.scope) < 1 ? 'green' : 'red',
              textAlign: 'right'
            }}>
              {formatAmount(calculateTotalScope())}
            </Typography>
          </Grid>
          <Grid size={2}/>
          <Grid size={2}>
            <Typography variant="body1" sx={{color: selectedContract.code >= 0 ? 'inherit' : 'red'}}>
              <strong>Realizováno: </strong>
            </Typography>
          </Grid>
          <Grid size={2}>
            <Typography variant="body1"
                        sx={{color: selectedContract.code >= 0 ? 'inherit' : 'red', textAlign: 'right'}}>
              {formatAmount(calculateActualPaymentSum(selectedContract.code))}
            </Typography>
          </Grid>
          <Grid size={2}/>
          <Grid size={2}>
            <Typography variant="body1"
                        sx={{color: (calculateTotalScope() - calculateActualPaymentSum(selectedContract.code)) >= 0 ? 'inherit' : 'red'}}>
              <strong>Zbývá
                dočerpat: </strong>
            </Typography>
          </Grid>
          <Grid size={2}>
            <Typography variant="body1"
                        sx={{
                          textAlign: 'right',
                          color: (calculateTotalScope() - calculateActualPaymentSum(selectedContract.code)) >= 0 ? 'inherit' : 'red'
                        }}>
              {formatAmount(calculateTotalScope() - calculateActualPaymentSum(selectedContract.code))}
            </Typography>
          </Grid>
          <Grid size={2}/>
          <Grid size={12}>
            <Divider sx={{marginBottom: '20px', marginTop: '20px'}}/>
          </Grid>
          <Grid size={1.5}>
            <Typography variant="body2" fontWeight="bold">
              Fáze
            </Typography>
          </Grid>
          <Grid size={1.5}>
            <Typography variant="body2" fontWeight="bold">
              Měsíc
            </Typography>
          </Grid>
          <Grid size={3.25}>
            <div style={{alignItems: 'center', display: 'inline-flex', width: '100%'}}>
              <Typography variant="body2" fontWeight="bold" marginLeft={1}>
                Plán
              </Typography>
              <DeleteIcon onClick={() => handleClearAssumption()}
                          sx={{cursor: 'pointer', fontSize: "1.2rem", marginLeft: 1}}/>
            </div>
          </Grid>
          <Grid size={3.25}>
            <div style={{alignItems: 'center', display: 'inline-flex', width: '100%'}}>
              <Typography variant="body2" fontWeight="bold" marginLeft={1.5}>
                Smlouva
              </Typography>
              <DeleteIcon onClick={() => handleClearScope()}
                          sx={{cursor: 'pointer', fontSize: "1.2rem", marginLeft: 1}}/>
            </div>
          </Grid>
          <Grid size={2.5}>
            <Typography variant="body2" fontWeight="bold" marginLeft={1.5}>
              Realizováno
            </Typography>
          </Grid>
        </Grid>
        {userValueList.length === 0 && (
          <Grid size={12} sx={{ paddingTop: 1, display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h7" sx={{ textAlign: 'center' }}>
              Načítám...
            </Typography>
          </Grid>
        )}
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          {userValueList.length > 0 && userValueList.map((userValue, index) => userValue.businessMonthScopeList.map((scope, idx) => {
            const assumption = userValue.businessMonthAssumptionList[idx];
            return (<React.Fragment key={`${index}-${idx}`}>
              <Grid size={1.5}>
                <Typography variant="body2">{userValue.label}</Typography>
              </Grid>
              <Grid size={1.5}>
                <Typography variant="body2">{formatDateAddSlash(scope.businessMonth)}</Typography>
              </Grid>
              <Grid size={3.25}>
                  <NumericFormat
                    customInput={TextField} // Use MUI TextField as the input component
                    value={assumption.value}
                    onValueChange={(values) => handleChangeAssumption(values, assumption.businessMonth, userValue.label)}// Update the value when the user types
                    onFocus={(e) => e.target.select()} // Select the text when the input is focused
                    thousandSeparator=" " // Format with commas for thousands separator
                    decimalSeparator="," // Use period for decimal separator
                    decimalScale={0} // Limit to 2 decimal places
                    allowNegative={false} // Disable negative values
                    isNumericString={true} // Make sure it's treated as a numeric string
                    size="small"
                  />
              </Grid>
              <Grid size={3.25}>
                  <NumericFormat
                    customInput={TextField} // Use MUI TextField as the input component
                    value={scope.value}
                    onValueChange={(values) => handleChangeScope(values, scope.businessMonth, userValue.label)}// Update the value when the user types
                    onFocus={(e) => e.target.select()} // Select the text when the input is focused
                    thousandSeparator=" " // Format with commas for thousands separator
                    decimalSeparator="," // Use period for decimal separator
                    decimalScale={0} // Limit to 2 decimal places
                    allowNegative={false} // Disable negative values
                    isNumericString={true} // Make sure it's treated as a numeric string
                    size="small"
                  />
              </Grid>
              <Grid size={2.5}>
                <Typography variant="body2" sx={{
                  color: (calculateActualPaymentMonthSum(
                    scope.businessMonth,
                    selectedContract.code,
                  )) >= 0 ? 'inherit' : 'red'
                }}>{formatAmount(calculateActualPaymentMonthSum(
                  scope.businessMonth,
                  selectedContract.code,
                ))}</Typography>
              </Grid>
            </React.Fragment>);
          }))}
        </Grid>
        <Grid container sx={{paddingTop: '20px'}} spacing={5}>
          <Grid size={6} sx={{display: 'flex', justifyContent: 'end'}}>
            <Button variant="outlined" disabled={saving} onClick={handleClose} startIcon={<CloseIcon/>}>
              Zrušit
            </Button>
          </Grid>
          <Grid size={6} sx={{display: 'flex', justifyContent: 'start'}}>
            <LoadingButton variant="contained" loading={saving} loadingPosition="end" onClick={handlePopUpSubmit}
                           endIcon={<SaveIcon/>}>
              Uložit
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default FinancialPlanPopUp;
