import React, { useContext } from 'react';
import { companiesColumns } from "../../data/DatatableColumns";
import { GlobalDataContext } from "../../components/GlobalDataProvider";
import EntityList from "../../components/EntityList";
import Sidebar from "../../components/Sidebar";


const Companies = () => {
  const { listOfCompanies } = useContext(GlobalDataContext);

  return (
    <Sidebar>
    <EntityList
      entityName="Firmy"
      listData={listOfCompanies}
      columns={companiesColumns}
      basePath="/company"
    />
    </Sidebar>
  );
};

export default Companies;
